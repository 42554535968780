<script setup lang="ts">
import zhTw from 'element-plus/es/locale/lang/zh-tw';
import useLoginStore from '@/stores/login';
import Loading from '@/components/Loading.vue';

const route = useRoute();
const loginStore = useLoginStore();
const locale = zhTw;

function closeTotalPopover() {
  loginStore.setLoginPopover(false);
}

onMounted(() => {
  document.getElementsByTagName('body')[0].setAttribute('data-version', GIT_VERSION);
});
</script>

<template>
  <el-config-provider :locale="locale">
    <template v-if="route.meta.useMainframe">
      <mainframe-header @click.capture="closeTotalPopover" />
      <mainframe-sidemenu @click.capture="closeTotalPopover" />
      <el-scrollbar class="el-scrollbar-main">
        <Loading :z-index="9999" />
        <div @click.capture="closeTotalPopover">
          <RouterView />
        </div>
        <mainframe-footer @click.capture="closeTotalPopover" />
      </el-scrollbar>
    </template>
    <template v-else>
      <el-scrollbar height="100dvh">
        <Loading :z-index="9999" />
        <RouterView />
      </el-scrollbar>
    </template>
    <Dialog />
  </el-config-provider>
</template>

<style scoped>
.el-scrollbar-main {
  height: calc(var(--vh, 1vh) * 100 - 60px);
  margin-top: 60px;
}
</style>
