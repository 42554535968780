<script setup lang="ts">
interface Props {
  title?: string
  content?: string
  type?: '' | 'danger'
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  content: '',
  type: '',
});

const classStr = computed(() => {
  const arr: string[] = [];
  if (props.title === '')
    arr.push('tooltip-info--title-less');
  if (props.type === 'danger')
    arr.push('tooltip-info--danger');
  return arr.join(' ');
});
</script>

<template>
  <div
    class="tooltip-info z-1"
    :class="classStr"
  >
    <span>{{ title }}</span>
    <div class="tooltip-info__icon">
      <el-tooltip
        placement="top" :content="content"
      >
        <i class="tooltip-icon icon-info_circle text-inherit" />
        <template #content>
          <slot />
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tooltip-info {
  display: inline-flex;
  align-items: center;

  &__icon {
    display: inline-flex;
    padding-left: 5px;

    .tooltip-info--title-less & {
      padding-left: 0;
    }
  }
}

.tooltip-info--danger .tooltip-icon {
  color: #fd3a54;
}
</style>
